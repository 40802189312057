.messenger-main {
  display: flex;
  height: 175px;
  flex-direction: column;
  border: 1px black solid;
  overflow-y: scroll;
  padding: 4px 16px;
}
.messenger-main::-webkit-scrollbar {
  width: 9px;
}
.messenger-main::-webkit-scrollbar-track {
  box-shadow: inset 0 0 9px 9px transparent;
  border: solid 3px transparent;
}
.messenger-main::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 9px 9px #000000;
  border: solid 3px transparent;
  border-radius: 100px;
}
.messenger-main::-webkit-scrollbar-button {
  display: none;
}
.message-bubble {
  position: relative;
  background-color: black;
  color: white;
  padding: 8px 16px;
  border-radius: 24px;
  margin-bottom: 4px;
  text-align: center;
}
.message-author-left {
  font-size: 12px;
  margin-left: 16px;
  text-align: left;
}
.message-author-right {
  font-size: 12px;
  margin-right: 16px;
  text-align: right;
}
.message-tail-left {
  left: 0;
  bottom: 0;
  position: absolute;
  width: 0; 
  height: 0; 
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 10px solid black;
}
.message-tail-right {
  right: 0;
  bottom: 0;
  position: absolute;
  width: 0; 
  height: 0; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid black;
}
.textfield-container {
  margin: -1px 0 0 0;
  display: flex;
  flex-direction: row;
  position: relative;
  border: 1px black solid;
  align-items: center;
  background-color: transparent;
  padding: 8px 16px;
}
.textfield {
  font-family: Poppins, Arial, serif;
  max-height: 200px;
  font-size: 14px;
  line-height: 20px;
  resize: none;
  flex: 1;
  border-radius: 0;
  background-color: transparent;
  border: none;
}
.textfield:focus {
  outline: none;
}
.textfield-container:focus-within {
  outline: 1px solid black;
}
.textfield-icon:hover {
  cursor: pointer;
}
.textfield::-webkit-scrollbar {
  width: 9px;
}
.textfield::-webkit-scrollbar-track {
  box-shadow: inset 0 0 9px 9px transparent;
  border: solid 3px transparent;
}
.textfield::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 9px 9px #000000;
  border: solid 3px transparent;
  border-radius: 100px;
}
.textfield::-webkit-scrollbar-button {
  display: none;
}
.message {

}