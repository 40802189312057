@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Poppins';
    font-weight: 900;
    src: local('Poppins'), url(./fonts/Poppins/Poppins-Bold.ttf) format('truetype');
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
}
ol, ul {
  padding-inline-start: 16px;
}
:root {
  --background: #F7EADC;
}
body {
  background-color: var(--background);
  font-family: Poppins, Arial, serif;
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  padding: 30px;
}
.header-text {
  font-size: 20px;
  font-weight: bold;
}
.card-title {
  font-size: 32px;
  font-weight: bold;
}
.card-description {
  font-size: 16px;
  font-weight: normal;
}
.divider {
  width: 100%;
  height: 1px;
  background-color: black;
}
.profile-sm {
  width: 32px;
  height: 32px;
  border-radius: 16px;
}
.blurb-title {
  font-size: 20px;
  font-weight: normal;
  text-align: center;
}
.blurb-description {
  font-size: 16px;
  font-weight: normal;
}
.download-button {
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  padding: 16px 0;
  position: sticky;
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  width: 100%;
}
.share-button {
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 80px;
  background-color: #000000;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  position: fixed;
  color: #ffffff;
}
.share-popup-show {
  visibility: hidden;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  top: 48px;
  background-color: #000000;
  padding: 8px;
  color: #ffffff;
  border-radius: 1000px;
  font-size: 12px;
  width: 200px;
  text-align: center;
  opacity: 1;
  animation: fade 3s;
}
.share-popup-hide {
  display: none;
}
@keyframes fade {
  0%,100% { opacity: 0; visibility: hidden; }
  20%,80% { opacity: 1; visibility: visible; }
}
@media screen and (min-width: 451px) {
  .share-button {
      right: calc(50% - 215px);
  }
}
@media screen and (max-width: 450px) {
  .share-button {
      right: 2%;
  }
}
.download-button:hover, .share-button:hover {
  color: lightgray;
  cursor: pointer;
}
.map {
  border: 1px solid black;
}
.loading {
  /* position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 20px;
  font-weight: bold; */
  padding: 0;
  display: block;
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%;
}